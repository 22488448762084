import { Menu } from "lucide-react";
import { Menubar, MenubarMenu, MenubarTrigger } from "@/components/ui/menubar";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "@/components/ui/sheet";
import { Link } from "react-router-dom";

export function ResponsiveMenubar() {
  const menuItems = [
    { name: "Home", href: "/" },
    { name: "RSVP", href: "/rsvp" },
  ];

  return (
    <Menubar className="fixed top-0 left-0 w-full flex justify-between items-center px-4 py-2 bg-transparent z-50 border-none">
      <div className="font-bold text-lg text-white">Your Logo</div>

      {/* Desktop Menu */}
      <div className="hidden md:flex gap-6">
        {menuItems.map((item) => (
          <MenubarMenu key={item.name}>
            <MenubarTrigger asChild>
              <Link to={item.href} className="px-3 py-2 text-xl font-medium text-white">
                {item.name}
              </Link>
            </MenubarTrigger>
          </MenubarMenu>
        ))}
      </div>

      {/* Mobile Menu (Hamburger) */}
      <div className="md:hidden">
        <Sheet>
          <SheetTrigger asChild>
            <Button variant="ghost" size="icon">
              <Menu className="h-6 w-6 text-white" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="right">
            <SheetHeader>
              <SheetTitle className="text-lg">Menu</SheetTitle>
              <SheetDescription className="text-sm text-muted-foreground">
                Use the links below to navigate the site.
              </SheetDescription>
            </SheetHeader>
            <nav className="flex flex-col gap-4 mt-4">
              {menuItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-xl font-medium text-black"
                >
                  {item.name}
                </Link>
              ))}
            </nav>
          </SheetContent>
        </Sheet>
      </div>
    </Menubar>
  );
}
